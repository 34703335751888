@keyframes beating {
  from, to {
    scale: 1;
  }

  85% {
    scale: 0.8;
  }
}

@keyframes shaking {
  from, to {
    transform: translate3d(0, 0, 0);
  }

  10%, 30%, 50%, 70%, 90% {
    transform: translate3d(-10px, 0, 0);
  }

  20%, 40%, 60%, 80% {
    transform: translate3d(10px, 0, 0);
  }
}

.canvas-wrapper {
  display: flex;
  width: 100%;
  height: 100vh;
  max-height: 100vh;

  .canvas {
    position: relative;
    width: 100%;
    height: 100%;
    margin: auto;
    max-width: #{'min(80vh, 80vw)'};
    max-height: #{'min(80vh, 80vw)'};

    &.beating {
      animation: 1s beating infinite;
    }

    &.shaking {
      animation: 1s shaking infinite;
    }
  }

  .controller {
    position: fixed;
    bottom: 0;
    right: 0;
    padding-right: 10px;
    padding-bottom: 10px;
    display: flex;
    flex-direction: column;
    gap: 10px;

    .control {
      display: flex;
      width: 100%;
      justify-content: space-between;
      color: #fff;

      .label {
        margin-right: 10px;
      }
    }
  }
}