@mixin petal-fly($isUp, $isRight) {
  $verticalFrom: if($isUp, 'bottom', 'top');
  $horizontalFrom: if($isRight, 'left', 'right');
  $verticalDirection: if($isUp, 'up', 'down');
  $horizontalDirection: if($isRight, 'right', 'left');
  @keyframes petal-fly-#{$verticalDirection}-#{$horizontalDirection} {
    from, to {
      #{$verticalFrom}: 60%;
      #{$horizontalFrom}: 60%;
      opacity: 0;
      scale: 1;
    }
    25% {
      #{$verticalFrom}: 60%;
      #{$horizontalFrom}: 60%;
      opacity: 0;
      scale: 0.5;
    }
    50% {
      #{$verticalFrom}: 100%;
      #{$horizontalFrom}: 100%;
      opacity: 1;
      scale: 2;
    }
    99% {
      #{$verticalFrom}: 90%;
      #{$horizontalFrom}: 90%;
      opacity: 0;
      scale: 2;
    }
  }


  .petal-fly-#{$verticalDirection}-#{$horizontalDirection}{
    position: absolute;
    max-width: 50%;
    max-height: 50%;
    z-index: 1;

    @if ($isUp) {
      bottom: 50%;
    } @else {
      top: 50%;
    }
    @if ($isRight) {
      left: 50%;
    } @else {
      right: 50%;
    }

    .petal {
      opacity: 0;
      position: absolute;
      animation: 1s infinite normal petal-fly-#{$verticalDirection}-#{$horizontalDirection} linear;
    }
  }
}

.petal-fly {
  position: relative;
  width: 100%;
  height: 100%;
}

@include petal-fly(true, true);
@include petal-fly(true, false);
@include petal-fly(false, true);
@include petal-fly(false, false);
